<template>
  <div class="es_reserve-service-container bg_gray">
    <div class="container margin_30_40">
      <router-link class="back-button mb-2" :to="getToRoute()">Inapoi</router-link>
      <div class="row mt-4">
        <div class="col-lg-6 col-md-6 mb-4">
          <div class="box_order">
            <div class="head">
              <h3>Plata si Adresa</h3>
            </div>
            <div>
              <div v-if="!getCards.length" class="alert alert-danger" role="alert">
                <span>{{ $t('generic.please_add_a_payment_method') }}</span>
              </div>
              <div class="">
                <div class="main">
                  <es-address-details @on-show-address-modal="isAddressModalOpen = true" />
                  <es-payment-details />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 mb-4 box_order">
            <es-subscription-selection v-if="showSubscription" @set-active-subscription="setActiveSubscription" />
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <es-reservation-summary
            :date="getSelectedDate"
            :time="getSelectedTime"
            :is-reservation-confirmed="isReservationConfirmed"
            :elite-id="getReservationDetails.target_elite_id"
          />
          <div class="d-flex my-4 justify-content-end">
            <button
              class="btn btn-sm btn-pink btn-pill px-6"
              :disabled="!getCards.length"
              @click.prevent="onPay()"
            >
              {{ $t('summary.sendReservation') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <es-address-modal
      v-if="isAddressModalOpen"
      v-model="isAddressModalOpen"
      :is-massage-view="isMassageView"
    />
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { ReservationSummary } from '@/components/shared/reservation-summary';
  import { PaymentDetails } from '@/components/shared/payment';
  import { SubscriptionSelection } from '@/components/shared/subscription-selection';
  import { AddressModal } from '@/components/shared/address-modal';
  import { AddressDetails } from '@/components/shared/address-details';
  import { loadStripe } from '@stripe/stripe-js';

  export default Vue.extend({
    name: 'es-reserve-service-payment',

    components: {
      'es-address-details': AddressDetails,
      'es-address-modal': AddressModal,
      'es-reservation-summary': ReservationSummary,
      'es-payment-details': PaymentDetails,
      'es-subscription-selection': SubscriptionSelection,
    },

    data: () => ({
      activeSubscription: null,
      isAddressModalOpen: false,
      isMassageView: null,
      isReservationConfirmed: false,
      stripe: null,
      payButtonAlreadyClicked: false,
    }),

    computed: {
      ...mapGetters({
        getAddresses: 'address/getAddresses',
        getCards: 'cards/getCards',
        getCardInfo: 'cards/getCardInfo',
        getSelectedCard: 'cards/getSelectedCard',
        getReservationDetails: 'services/getReservationDetails',
        getServiceById: 'services/getServiceById',
        getSelectedTime: 'services/getSelectedTime',
        getSelectedDate: 'services/getSelectedDate',
        isAuthenticated: 'session/isAuthenticated',
      }),
      showSubscription() {
        /* eslint-disable */
        const { reservation_service } = this.getReservationDetails;
        if (reservation_service && reservation_service.beauty_service_reservations) {
          const mainService = reservation_service.beauty_service_reservations[0].service;
          if (mainService.name.includes('man_manicure')) {
            return false;
          }

          if (mainService.name.includes('manicure') || mainService.name.includes('pedicure')) {
            return true;
          } else {
            return false;
          }

        }
        return true;
      },
    },

    watch: {
      getReservationDetails(newVal) {
        if (newVal.payment_status === 'requires_action') {
          this.initialise3dSecure(newVal);
        }

        if (newVal.status === 'waiting_confirmation') {
          this.$router.push(`${this.getToRoute()}/service-payment-success`)
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      },
    },

    async created() {
      const { type } = this.$router.currentRoute.params;
      this.isMassageView = type === 'single' || type === 'couple';
      await this.fetchAddresses();
    },

    methods: {
      ...mapActions({
        payServiceReservation: 'services/payServiceReservation',
        fetchCards: 'cards/fetchCards',
        fetchAddresses: 'address/fetchAddresses',
        createReservation: 'services/createReservation',
        createExtraServiceReservation: 'services/createExtraServiceReservation',
        fetchStripeKeys: 'cards/fetchStripeKeys',
      }),

      getToRoute() {
        const { id, type } = this.$router.currentRoute.params;
        return `/servicii/${type}/${id}/rezerva`;
      },

      async initialise3dSecure(data) {
        const { client_secret } = data;

        await this.fetchStripeKeys();

        this.stripe = await loadStripe(this.getCardInfo.publicKey);
        const stripeData = await this.stripe.confirmCardPayment(client_secret);
        const { error, paymentIntent } = stripeData;

        if (error) {
          this.$toasts.toast({
            id: 'address-toast',
            intent: 'error',
            title: this.$t('toast.error_title'),
            message: this.$t('toast.3dsecureError'),
          });
        } else {
          if (paymentIntent.status === 'requires_capture') {
            this.$router.push(`${this.getToRoute()}/service-payment-success`)
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }

        }
      },

      async onPay() {
        if (this.payButtonAlreadyClicked === true) {
          return;
        }
        this.payButtonAlreadyClicked = true;
        const paymentMethodId = this.getSelectedCard.payment_method_id;
        if (!this.getAddresses.length) {
          this.isAddressModalOpen = true;
          this.$toasts.toast({
            id: 'address-toast',
            title: this.$t('toast.warning_title'),
            message: this.$t('toast.no_address'),
          });
          this.payButtonAlreadyClicked = false;
          return;
        }

        if (!this.getReservationDetails) {
          await this.createReservation();
          await this.createExtraServiceReservation();
        }

        if (!paymentMethodId) {
          await this.fetchCards();
        }

        try {
          await this.payServiceReservation();
        } catch (error) {
          this.payButtonAlreadyClicked = false;
        } finally {
          this.payButtonAlreadyClicked = false;
        }
      },

      setActiveSubscription(subscription) {
        this.activeSubscription = subscription;
      },
    },
  });
</script>
